import logo from './logo.svg';
import styled from 'styled-components';
import './App.css';

import skinImage from './assets/skin2.png';

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const Skin = styled.img`
  width: 70%;
  max-width: 800px;
`;

const Teaser = styled.h1`
  font-size: 10rem;
  position: absolute;
  color: white;
`;

// const EmailInput = styled.input.attrs({ type: 'email', placeholder: 'join the list' })`
//   border: none;
//   background: transparent;
//   outline: none;
//   font-family: Brownist;
//   font-size: 2rem;
//   border-bottom: 2px dotted black;
// `;

function App() {
  return (
    <div className="App">
      <Body>
        <Skin src={skinImage} alt="skin" />
        <Teaser>Soon</Teaser>
        {/* <EmailInput /> */}
      </Body>
    </div>
  );
}

export default App;
